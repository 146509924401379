#lang-svg {
    position: absolute;
    cursor: pointer;
}

#lang-toggle {
    position: fixed;
}

#lang-triangle {
    position: fixed;
    width: 40vh;
    width: calc(var(--vh, 1vh) * 40);
    height: 30vh;
    height: calc(var(--vh, 1vh) * 30);
    transform: rotate(-30deg);
    left: -15vh;
    left: calc(var(--vh, 1vh) * -15);
    top: -20vh;
    top: calc(var(--vh, 1vh) * -20);
}

@media only screen and (max-aspect-ratio: 1/1) {
    #lang-svg {
        height: 100%;
    }

    #lang-toggle {
        height: 4vh;
        height: calc(var(--vh, 1vh) * 4);
        top: 3.8vh;
        top: calc(var(--vh, 1vh) * 3.8);
        left: 6vw;
    }
}

@media only screen and (min-aspect-ratio: 1/1) {
    #lang-triangle {
        visibility: hidden;
    }

    #lang-svg {
        width: 100%;
    }

    #lang-toggle {
        width: 5.33vw;
        left: 6vw;
        top: 3.8vw;
    }
}
