#all-rights-reserved-project-page {
    font-size: 1.5vh;
    font-size: calc(var(--vh, 1vh) * 1.5);
    font-family: 'Klee One', 'Trebuchet MS', sans-serif;
    color: #999999;
    text-align: center;
}

#project-close-icon {
    position: absolute;
    top: 3vh;
    top: calc(var(--vh, 1vh) * 3);
    right: 3vh;
    right: calc(var(--vh, 1vh) * 3);
    width: 4vh;
    width: calc(var(--vh, 1vh) * 4);
    z-index: 100;
}

.project-entry {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.project-header {
    font-family: 'Braah One', sans-serif;
    font-weight: normal;
    font-size: 3.3vh;
    font-size: calc(var(--vh, 1vh) * 3.3);
    color: #292929;
    margin-bottom: -2vh;
    margin-bottom: calc(var(--vh, 1vh) * -2);
    text-align: center;
}

.project-image {
    width: min(60vw, 420px);
}

.project-text {
    font-family: 'Klee One', sans-serif;
    font-weight: normal;
    font-size: 2.25vh;
    font-size: calc(var(--vh, 1vh) * 2.25);
    text-align: center;
}

.project-when {
    font-size: 1.5vh;
    font-size: calc(var(--vh, 1vh) * 1.5);
    font-family: 'Klee One', sans-serif;
    text-align: center;
}

#project-text-container {
    padding: 3vw 0 12vw;
    display: flex;
    flex-direction: column;
    gap: 5vw;
}

@media only screen and (min-aspect-ratio: 1/1) {
    #project-text-container {
        padding: 3vw 0 0;
    }
}

@media only screen and (min-aspect-ratio: 3/2) {
    .project-entry {
        flex-direction: row;
        gap: 5vh;
        gap: calc(var(--vh, 1vh) * 5);
    }

    .project-header {
        text-align: left;
    }

    .project-image {
        width: min(40vh, 420px);
        width: min(calc(var(--vh, 1vh) * 40), 420px);
    }

    .project-text {
        text-align: left;
    }

    .project-when {
        text-align: left;
    }

    #project-text-container {
        padding: 3vw 2vw 0;
    }
}
