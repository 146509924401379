@import url('https://fonts.googleapis.com/css?family=Braah+One');
@import url('https://fonts.googleapis.com/css?family=Galindo');
@import url('https://fonts.googleapis.com/css?family=Klee+One');

.banner-item {
    position: fixed;
}

@media only screen and (max-aspect-ratio: 1/1) {
    .banner-item {
        height: 100%;
        left: 50.1%;
        transform: translate(-50%, 0%);
    }
}

@media only screen and (min-aspect-ratio: 1/1) {
    .banner-item {
        width: 100%;
    }
}