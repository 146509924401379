#all-rights-reserved-bio-panel {
    font-size: 1.5vh;
    font-size: calc(var(--vh, 1vh) * 1.5);
    font-family: 'Klee One', 'Trebuchet MS', sans-serif;
    color: #999999;
    text-align: center;
}

#bio-close-icon {
    position: absolute;
    top: 3vh;
    top: calc(var(--vh, 1vh) * 3);
    right: 3vh;
    right: calc(var(--vh, 1vh) * 3);
    width: 4vh;
    width: calc(var(--vh, 1vh) * 4);
    z-index: 100;
}

#bio-panel-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.plain-link {
    color: #222222;
    font-style: inherit;
    font-weight: inherit;
}

#portrait-image {
    margin: 0 auto;
    width: min(100%, 512px);
}

#start-heading {
    font-family: 'Braah One', sans-serif;
    font-weight: normal;
    font-size: 4vh;
    font-size: calc(var(--vh, 1vh) * 4);
    color: #292929;
    margin-bottom: -2vh;
    margin-bottom: calc(var(--vh, 1vh) * -2);
    text-align: center;
}

.text {
    font-family: 'Klee One', sans-serif;
    font-weight: 400;
    font-size: 2.25vh;
    font-size: calc(var(--vh, 1vh) * 2.25);
    text-align: center;
}

.text-bold {
    font-weight: bold;
    color: #222222;
}

#text-container {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 12vh;
    padding-bottom: calc(var(--vh, 1vh) * 12);
}

#view-my-projects {
    font-family: 'Braah One', sans-serif;
    font-size: 3vh;
    font-size: calc(var(--vh, 1vh) * 3);
    color: #AAAAAA;
    margin: 1vh 0;
    margin: calc(var(--vh, 1vh) * 1) 0;
}

#view-my-projects:hover {
    color: #777777;
    cursor: pointer;
}

#view-my-projects-container {
    display: flex;
    justify-content: center;
}

@media only screen and (min-aspect-ratio: 1/1) {
    #all-rights-reserved-bio-panel {
        text-align: left;
    }

    #bio-panel-container {
        flex-direction: row;
    }

    #portrait-image {
        width: min(40vh, 512px);
        width: min(calc(var(--vh, 1vh) * 40), 512px);
        margin-top: 6vh;
        margin-top: calc(var(--vh, 1vh) * 6);
    }

    #start-heading {
        font-size: 6.5vh;
        font-size: calc(var(--vh, 1vh) * 6.5);
        text-align: left;
    }

    .text {
        font-size: 2.3vh;
        font-size: calc(var(--vh, 1vh) * 2.3);
        margin-right: 5vh;
        margin-right: calc(var(--vh, 1vh) * 5);
        text-align: left;
    }

    #text-container {
        padding-top: 3vh;
        padding-top: calc(var(--vh, 1vh) * 3);
        padidng-left: 3vh;
        padding-left: calc(var(--vh, 1vh) * 3);
        padding-bottom: 0;
    }

    #view-my-projects-container {
        justify-content: start;
    }
}

@media only screen and (min-aspect-ratio: 3/2) {
    #all-rights-reserved-bio-panel {
        text-align: left;
    }

    #portrait-image {
        width: min(60vh, 512px);
        width: min(calc(var(--vh, 1vh) * 60), 512px);
    }

    #start-heading {
        text-align: left;
    }

    .text {
        font-size: 2.5vh;
        font-size: calc(var(--vh, 1vh) * 2.5);
        margin-right: 7vh;
        margin-right: calc(var(--vh, 1vh) * 7);
        text-align: left;
    }
}