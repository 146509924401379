#hamburger-open-icon, #hamburger-close-icon {
    position: absolute;
    top: 3vh;
    top: calc(var(--vh, 1vh) * 3);
    right: 3vh;
    right: calc(var(--vh, 1vh) * 3);
    width: 4vh;
    width: calc(var(--vh, 1vh) * 4);
    z-index: 100;
}

#hamburger-link-container {
    display: inline-flex;
    flex-direction: column;
    z-index: 100; /* z-index is needed for links to render on top of the background darkening from bio panel */
}

@media only screen and (min-aspect-ratio: 1/1) {
    #hamburger-open-icon {
        visibility: hidden;
    }
}