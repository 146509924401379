#nav-link-container {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3.1vh;
    margin-top: calc(var(--vh, 1vh) * 3.1);
    z-index: 100; /* Needed for links to render on top of the background darkening from bio panel */
}

@media only screen and (max-aspect-ratio: 1/1) {
    #nav-link-container {
        visibility: hidden;
    }
}