#favourite-colour-text {
    position: absolute;
    left: 15px;
    bottom: -5px;
    font-size: max(3vw, 12px);
    font-weight: 600;
    font-family: 'Klee One', 'Trebuchet MS', sans-serif;
    animation: fade-in 4s;
}

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateY(0);
    }
    80% {
        opacity: 0;
        transform: translateY(0);
    }
    90% {
        opacity: 0.5;
        transform: translateY(-2vh);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media only screen and (min-aspect-ratio: 2/3) {
    #favourite-colour-text {
        bottom: -6px;
        font-size: 1.8vh;
        font-size: calc(var(--vh, 1vh) * 1.8);
    }
}